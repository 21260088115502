import { httpClient } from '@/services/httpClient'
import { useEndpoints } from '@/composables/api/useEndpoints'

export const useCountryIndex = async () => {
  let detailsRes, errorRes
  try {
    detailsRes = await httpClient
      .get(useEndpoints.dropdown.country())
      .then(({ data }) => data.data)
  } catch (error) {
    errorRes = error?.response
    console.error('useCountryIndex', errorRes)
  }

  return new Promise((resolve, reject) => {
    if (detailsRes) {
      resolve({ data: detailsRes })
    } else {
      reject({ message: `Server Error!: useCountryIndex()`, error: errorRes })
    }
  })
}

export const useCountryIndexReq = httpClient.get(
  useEndpoints.dropdown.country()
)
